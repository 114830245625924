/*@ngInject*/
export default ($translateProvider: ng.translate.ITranslateProvider) => {
  $translateProvider.translations('sv', {
    ARCHIVE_MAINTENANCE: {
      ADD_APPRAISAL_TEMPLATE_TO_IP: 'Lägg till/ta bort gallringsmallar till IPn',
      ADD_CONVERSION_TEMPLATE_TO_IP: 'Lägg till/ta bort konverteringsmallar till IPn',
      ADD_IPS_TO_APPRAISAL_JOB: 'Lägg till informationspaket i gallringsjobb',
      ADD_IPS_TO_CONVERSION_JOB: 'Lägg till informationspaket i konverteringsjobb',
      ADD_NODES_TO_APPRAISAL_JOB: 'Lägg till noder i gallringsjobb',
      ADD_SEARCH_RESULT_TO_APPRAISAL_JOB: 'Lägg till sökresultat i gallringsjobb',
      ADD_TEMPLATE: 'Lägg till mall',
      ADD_TO_APPRAISAL_JOB: 'Lägg till i gallringsjobb',
      APPRAISAL_JOB: 'Gallringsjobb',
      APPRAISAL_JOBS: 'Gallringsjobb',
      APPRAISAL_PREVIEW: 'Gallring förhandsgranskning',
      APPRAISAL_TEMPLATE: 'Gallringsmall',
      APPRAISAL_TEMPLATES: 'Gallringsmallar',
      APPRAISAL_TEMPLATE_REMOVED: 'Gallringsmall: {{name}} har tagits bort',
      ARCHIVE_MAINTENANCE: 'Arkivvård',
      ARCHIVE_OBJECTS: 'Arkivobjekt',
      CHOOSE_AT_LEAST_ONE_PATH: 'Välj minst en sökväg',
      CHOOSE_AT_LEAST_ONE_SPECIFICATION: 'Välj minst en specifikation',
      CONVERSION_JOB: 'Konverteringsjobb',
      CONVERSION_JOB_REMOVED: 'Konverteringsjobb har tagits bort',
      CONVERSION_JOBS: 'Konverteringsjobb',
      CONVERSION_PREVIEW: 'Konvertering förhandsgranskning',
      CONVERSION_RULES: 'Konverteringsregler',
      CONVERSION_TEMPLATE: 'Konverteringsmall',
      CONVERSION_TEMPLATES: 'Konverteringsmallar',
      CONVERSION_TEMPLATE_REMOVED: 'Konverteringsmall: {{name}} har tagits bort',
      CREATE_APPRAISAL_JOB: 'Skapa gallringsjobb',
      CREATE_APPRAISAL_JOB_AND_RUN: 'Skapa och kör jobb',
      CREATE_APPRAISAL_TEMPLATE: 'Skapa gallringsmall',
      CREATE_CONVERSION_JOB: 'Skapa konverteringsjobb',
      CREATE_CONVERSION_JOB_AND_RUN: 'Skapa och kör jobb',
      CREATE_APPRAISAL_JOB_FROM_TEMPLATE: 'Skapa gallringsjobb från mall: {{name}}',
      CREATE_CONVERSION_JOB_FROM_TEMPLATE: 'Skapa konverteringsjobb från mall: {{name}}',
      CREATE_CONVERSION_TEMPLATE: 'Skapa konverteringsmall',
      CREATE_JOB: 'Skapa jobb',
      CREATE_TEMPLATE: 'Skapa mall',
      DO_YOU_WANT_TO_REMOVE_APPRAISAL: 'Vill du ta bort gallringsmallen: {{name}}',
      DO_YOU_WANT_TO_REMOVE_APPRAISAL_JOB: 'Vill du ta bort gallringsjobb',
      DO_YOU_WANT_TO_REMOVE_CONVERSION: 'Vill du ta bort konverteringsmallen: {{name}}',
      DO_YOU_WANT_TO_REMOVE_CONVERSION_JOB: 'Vill du ta bort konverteringsjobb',
      DO_YOU_WANT_TO_REMOVE_SPECIFICATION_ITEM: 'Vill du ta bort konverteringsregel',
      EDIT_APPRAISAL_JOB: 'Redigera gallringsjobb',
      EDIT_APPRAISAL_TEMPLATE: 'Redigera gallringsmall',
      EDIT_CONVERSION_JOB: 'Redigera konverteringsjobb',
      EDIT_CONVERSION_TEMPLATE: 'Redigera konverteringsmall',
      FILES_TO_BE_CONVERTED: 'Filer som kommer konverteras',
      FILES_TO_BE_REMOVED: 'Filer som kommer att tas bort',
      FINISHED: 'Avslutade',
      FULL_IP_APPRAISAL: 'Gallra hela informationspaket',
      JOB_CREATED: 'Jobb skapat',
      JOB_RUNNING: 'Jobb körs',
      JOB_SAVED: 'Jobb sparat',
      MANUAL_APPRAISAL: 'Manuell gallring',
      NEXT: 'Nästa',
      NO_FILES_TO_REMOVE: 'Det finns inga filer att ta bort',
      NO_PATHS_CHOSEN: 'Inga sökvägar valda',
      NO_SPECIFICATIONS_CHOSEN: 'Inga specifikationer valda',
      NO_REPORT_AVAILABLE: 'Ingen rapport tillgänglig',
      NODE_ADDED_TO_APPRAISAL_TEMPLATE: '{{node}} har lagts till i gallringsmall: {{TEMPLATE}}',
      NODE_ADDED_TO_CONVERSION_TEMPLATE: '{{node}} har lagts till i konverteringsmall: {{TEMPLATE}}',
      NODE_REMOVED_FROM_APPRAISAL_JOB: 'Noden har tagits bort från gallringsjobb',
      NODES_ADDED_TO_APPRAISAL_JOB: 'Noder tillagda i gallringsjobb',
      ONE_SPECIFICATION_REQUIRED: 'En specifikation krävs för att köra eller schemalägga ett jobb',
      ONGOING: 'Pågående',
      PATHS: 'Sökvägar',
      PREPAREAPPRAISALDESC: 'Skapa ny gallringsmall',
      PREPARECONVERSIONDESC: 'Skapa ny konverteringsmall',
      PREVIEW: 'Förhandsgranska',
      REMOVE_NODE_FROM_APPRAISAL_JOB: 'Ta bort nod från gallringsjobb',
      REPORT: 'Rapport',
      RUN_APPRAISAL_JOB: 'Kör gallringsjobb',
      RUN_CONVERSION_JOB: 'Kör konverteringsjobb',
      SELECT_INFORMATION_PACKAGES: 'Välj informationspaket',
      SELECTED_NODES: 'Valda noder',
      TEMPLATE: 'Mall',
      TEMPLATE_CREATED: 'Mall skapad',
      RUN: 'Kör',
      RUN_MANUALLY: 'Kör manuellt',
      SPECIFICATION: 'Specifikation',
      TOOL: 'Verktyg',
    },
  });
};
