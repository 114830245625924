/*@ngInject*/
export default ($translateProvider: ng.translate.ITranslateProvider) => {
  $translateProvider.translations('en', {
    ARCHIVE_MAINTENANCE: {
      ADD_APPRAISAL_TEMPLATE_TO_IP: 'Add/remove destruction templates to IPs',
      ADD_CONVERSION_TEMPLATE_TO_IP: 'Add/remove converison templates to IPs',
      ADD_IPS_TO_APPRAISAL_JOB: 'Add information packages to destruction job',
      ADD_IPS_TO_CONVERSION_JOB: 'Add information packages to conversion job',
      ADD_NODES_TO_APPRAISAL_JOB: 'Add nodes to desctruction job',
      ADD_SEARCH_RESULT_TO_APPRAISAL_JOB: 'Add search result to destruction job',
      ADD_TEMPLATE: 'Add template',
      ADD_TO_APPRAISAL_JOB: 'Add to destruction job',
      APPRAISAL_JOB: 'Destruction job',
      APPRAISAL_JOBS: 'Destruction jobs',
      APPRAISAL_PREVIEW: 'Destruction preview',
      APPRAISAL_TEMPLATE: 'Destruction template',
      APPRAISAL_TEMPLATES: 'Destruction templates',
      APPRAISAL_TEMPLATE_REMOVED: 'Destruction template: {{name}} has been removed',
      ARCHIVE_MAINTENANCE: 'Archive maintenance',
      ARCHIVE_OBJECTS: 'Archive objects',
      CHOOSE_AT_LEAST_ONE_PATH: 'Choose at least one path',
      CHOOSE_AT_LEAST_ONE_SPECIFICATION: 'Choose at least one specification',
      CONVERSION_JOB: 'Conversion job',
      CONVERSION_JOB_REMOVED: 'Conversion job removed',
      CONVERSION_JOBS: 'Conversion jobs',
      CONVERSION_PREVIEW: 'Conversion preview',
      CONVERSION_RULES: 'Conversion rules',
      CONVERSION_TEMPLATE: 'Conversion template',
      CONVERSION_TEMPLATES: 'Conversion templates',
      CONVERSION_TEMPLATE_REMOVED: 'Conversion template: {{name}} has been removed',
      CREATE_APPRAISAL_JOB: 'Create destruction job',
      CREATE_APPRAISAL_JOB_AND_RUN: 'Create and run job',
      CREATE_APPRAISAL_JOB_FROM_TEMPLATE: 'Create destruction job from template: {{name}}',
      CREATE_APPRAISAL_TEMPLATE: 'Create destruction template',
      CREATE_CONVERSION_JOB: 'Create conversion job',
      CREATE_CONVERSION_JOB_AND_RUN: 'Create and run job',
      CREATE_CONVERSION_JOB_FROM_TEMPLATE: 'Create conversion job from template: {{name}}',
      CREATE_CONVERSION_TEMPLATE: 'Create conversion templates',
      CREATE_JOB: 'Create job',
      CREATE_TEMPLATE: 'Create template',
      DO_YOU_WANT_TO_REMOVE_APPRAISAL: 'Do you want to remove destruction template: {{name}}',
      DO_YOU_WANT_TO_REMOVE_APPRAISAL_JOB: 'Do you want to remove destruction job',
      DO_YOU_WANT_TO_REMOVE_CONVERSION: 'Do you want to remove conversion template: {{name}}',
      DO_YOU_WANT_TO_REMOVE_CONVERSION_JOB: 'Do you want to remove conversion job',
      DO_YOU_WANT_TO_REMOVE_SPECIFICATION_ITEM: 'Do you want to remove conversion rule',
      EDIT_APPRAISAL_JOB: 'Edit destruction job',
      EDIT_APPRAISAL_TEMPLATE: 'Edit destruction template',
      EDIT_CONVERSION_JOB: 'Edit conversion job',
      EDIT_CONVERSION_TEMPLATE: 'Edit conversion template',
      FILES_TO_BE_CONVERTED: 'Files to be converted',
      FILES_TO_BE_REMOVED: 'Files to be removed',
      FINISHED: 'Finished',
      FULL_IP_APPRAISAL: 'Remove whole information packages',
      JOB_CREATED: 'Job created',
      JOB_RUNNING: 'Job running',
      JOB_SAVED: 'Job saved',
      MANUAL_APPRAISAL: 'Manual destruction',
      NEXT: 'Next',
      NO_FILES_TO_REMOVE: 'There are no files to remove',
      NO_REPORT_AVAILABLE: 'No report available',
      NODE_ADDED_TO_APPRAISAL_TEMPLATE: '{{node}} has been added to destruction template: {{template}}',
      NODE_ADDED_TO_CONVERSION_TEMPLATE: '{{node}} has been added to conversion template: {{template}}',
      NODE_REMOVED_FROM_APPRAISAL_JOB: 'Node removed from destruction job',
      NODES_ADDED_TO_APPRAISAL_JOB: 'Nodes added to destruction job',
      NO_PATHS_CHOSEN: 'No paths chosen',
      NO_SPECIFICATIONS_CHOSEN: 'No specifications chosen',
      ONE_SPECIFICATION_REQUIRED: 'A specification is required to run or schedule a job',
      ONGOING: 'Ongoing',
      PATHS: 'Paths',
      PREPAREAPPRAISALDESC: 'Create new destruction template',
      PREPARECONVERSIONDESC: 'Create new conversion template',
      PREVIEW: 'Preview',
      REMOVE_NODE_FROM_APPRAISAL_JOB: 'Remove node from destruction job',
      REPORT: 'Report',
      RUN_APPRAISAL_JOB: 'Run destruction job',
      RUN_CONVERSION_JOB: 'Run conversion job',
      SELECT_INFORMATION_PACKAGES: 'Select information packages',
      SELECTED_NODES: 'Selected nodes',
      TEMPLATE: 'Template',
      TEMPLATE_CREATED: 'Template created',
      RUN: 'Run',
      RUN_MANUALLY: 'Run manually',
      SPECIFICATION: 'Specification',
      TOOL: 'Tool',
    },
  });
};
